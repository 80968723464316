import React from 'react';

function Details() { 
  return (
    <div>
    Details
    </div>
  );
}

export default Details;