import React from 'react';

function Extras() { 
  return (
    <div>
    Extras
    </div>
  );
}

export default Extras;